<template>
  <div class="wrapper">
    <div class="header">
      <div style="background: #000000">
        <div class="header__lang">

            <select class="header__lang__changelang"   @change="selectFn($event)">
              <option  v-for="(item,index) in items" :key="index" :value="item.selectpdf"><span class="header__lang__option">{{item.name}}</span></option>
            </select>
            <div class="header__lang__download" >
              <span @click="downloadpdf()" class="iconfont header__lang__download__icon">&#xe62c;</span>
              <span class=" header__lang__download__title">PDF</span>
            </div>
        </div>
      </div>
    </div>
    <div class="pdfl">
      <div id="demo"></div>
    </div>
  </div>
</template>


<script>
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";

export default {
  name: "App",

  data() {
    return {
      pdfh5: null,
      pdfname: "",
      selectItem: 'English',
      items: [
                {selectpdf:'SXmini-G2Manual-English-v1.97b.pdf', name: 'English'},
                {selectpdf:'SXmini-G2Manual-Chinese-v1.95.pdf', name: '简体中文'}
      ]
    };
    
            
  },

  mounted() {
    this.pdfname = "SXmini-G2Manual-English-v1.97b.pdf";
    //实例化
    this.pdfh5 = new Pdfh5("#demo", {
      pdfurl:
        "https://sxupdatefile.oss-ap-southeast-1.aliyuncs.com/G2/" + this.pdfname,
      zoomEnable: false,
      // renderType:"svg",
      // lazy:true
    });
    //监听完成事件
    // this.pdfh5.on("complete", function (status, msg, time) {
    //   console.log(
    //     "状态：" +
    //       status +
    //       "，信息：" +
    //       msg +
    //       "，耗时：" +
    //       time +
    //       "毫秒，总页数：" +
    //       this.totalNum
    //   );
    // });
  },
  methods: {
    selectFn(e) {
                // console.log(e)
                // console.log(e.target.selectedIndex) // 选择项的index索引
                console.log(e.target.value)
                
                this.pdfname = "https://sxupdatefile.oss-ap-southeast-1.aliyuncs.com/G2/"+e.target.value
                //实例化
    this.pdfh5 = new Pdfh5("#demo", {
      pdfurl:"https://sxupdatefile.oss-ap-southeast-1.aliyuncs.com/G2/"+e.target.value,
      zoomEnable: false,
      // renderType:"svg",
      // lazy:true
    });
               
    },

    downloadpdf(){
      this.pdfh5.download(this.pdfname)
    },

  },
};
</script>

<style lang="scss" scoped>
@import "../../style/viriables.scss";

.wrapper {
  background: #000000;
  opacity: 1;
  border-radius: 0rem;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0rem;
  right: 0;
}

.iframe {
  position: absolute;
  top: 350px;
  left: 0;
  right: 0;
  bottom: 0rem;
}

.headericon {
  font-size: 30px;
}
.header {
  &__controller {
    display: flex;
    flex-wrap: nowrap;
    height: 130px;
    background: #000000;
    opacity: 1;
    text-align: center;
    font-family: BebasNeue;
    vertical-align: middle;
    font-size: 16px;

    &__item {
      margin-right: 2px;
      width: 123px;
      height: 96px;
      color: #1fa4fc;
      background: linear-gradient(180deg, #5e5e5e 0%, #131313 100%);
      vertical-align: middle;

      &__title {
        padding-left: 40px;
        color: #ffffff;
        font-size: 38px;
        padding-top: 18px;
        width: 42px;
        height: 42px;
        vertical-align: middle;
        padding-bottom: 7px;
      }

      &__content {
        display: inline-block;
        font-size: 16px;
        bottom: 0;
        height: 19px;
        color: #ffffff;
      }

      a {
        color: $content-fontcolor;
        text-decoration: none;
      }
      &--active {
        .header__controller__item__title {
          color: #00fff7;
        }
        .header__controller__item__content {
          color: #00fff7;
        }
      }
    }
  }

 

  &__lang {
    margin-left: 6px;
    background: #000000;
    height: 100px;
    text-align: left;
    font-family: BebasNeue;

    &__main {
      display: inline-block;
      color: #5e5e5e;
      padding-top: 10px;
      height: 39px;
      width: 157px;
      font-size: 26px;
      background: #ffffff;
      border-radius: 14px;
    }

    &__changelang {
      display: inline-block;
      margin-left: 282px;
      margin-top: 21px;
      color: #5e5e5e;
      padding-top: 3px;
      padding-left: 50px;

      height: 50px;
      width: 195px;
      font-size: 25px;
      background: #ffffff;
      font-family: BebasNeue;
      border-radius: 14px;
    }

    &__download{
      display: inline-block;
      width: 56px;
      height: 56px;
      
      margin-left: 110px;
      vertical-align: -5px;
      background: #FFFFFF;
      border: 2px solid #707070;
      opacity: 1;
      border-radius: 28px;
      &__title{
        padding-left: 19px;
        font-size: 39px;
        font-family: BebasNeue;
        color: #FFFFFF;
      }
      &__icon{
        padding-top: 9px;
        padding-left: 8px;
        color: #434343;
        font-size: 40px;
      }
    }

    &__themeselect {
      color: #5e5e5e;
    }
    &__span {
      display: inline-block;
      text-align: center;
      margin-left: 18px;
      line-height: 1;
    }

    &__icon {
      width: 20px;
      height: 18px;
      margin-left: 6px;
      margin-right: 6px;
      font-size: 26px;
    }
  }
}
</style>
<style  lang="scss" >
:root {
  --van-popover-action-width: 196px;
  --van-popover-action-height: 60px;
  --van-popover-action-font-size: 30px;
  // --van-toast-loading-icon-color:red;
}
</style>